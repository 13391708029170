.node-type-residency-event{

  #breadcrumb{
    display: none;
  }

  .field-collection-item-field-date-time{
    &:after{
      clear: none;
      content: none;
    }
  }

  .pane-node-field-primary-genre,
  .pane-node-field-secondary-genre,{
    display: inline;
    text-transform: uppercase;
    font-family: $font-family-headings;
    font-size: 0.8em;
    div{
      display: inline;
    }
  }
  .pane-node-field-secondary-genre{
    &:before{
      content: " | ";
    }
  }

  .pane-page-title{
    font-size: 1.6em;
    font-weight: 500;
    h2{
      margin: 0;
    }
    .pane-content{
      display: none;
    }
  }


  .pane-node-field-other-location{
    font-family: $font-family-headings;
    font-weight: 500;
    padding: 5px 0;
  }


  .pane-node-field-ticket-prices{
    font-family: $font-family-headings;
    font-size: 0.8em;
    padding-bottom: 5px;
    .pane-title{
      font-weight: 500;
      text-transform: uppercase;
    }
  }


  .pane-node-field-date-time{
    font-family: $font-family-headings;
    font-size: 0.9em;
    padding-bottom: 20px;
    .entity-field-collection-item{
      position: relative;
      padding: 5px 0;
      .field{
        display: inline-block;
        div{
          display: inline-block;
        }
      }
      .field-name-field-performance-date{
        width: 330px;
      }
    }
  }

  .pane-node-body{
    font-size: 0.9em;
  }

  .pane-node-field-related-event{
    padding-bottom: 20px;
  }
}