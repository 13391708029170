// Home Page

.front{
  .hancher-column-content-row1{
    margin-top: 14px;
    margin-bottom: 5px;
    .view-id-home_page_features{

      .view-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media only screen and (max-width: $screen-md) {
          .views-row{
            margin: 0 auto 20px;
          }
        }
      }
    }
  }
  .hancher-column-content-row2{
    display: none;
  }
  .hancher-thirdrow-twothirds{
    @include pane-shadow();
    font-family: $font-family-headings;
    h2{
      font-size: 1.3em;
      padding: 20px 0 10px 20px;
    }
    table{
      font-size: 0.9em;
      margin-bottom: 0;
      border:0;
      thead,tbody, tr, tr td, tr th {
        border:0;
      }
      td{
        max-width: 0;
        padding: 8px 0;
      }
      .views-field-field-performance-date{
        padding-left: 20px;
        max-width: 0;
        width: 20%;
        @media only screen and (max-width: $screen-sm) {
          width: 16%;
          .time{
            display: none;

          }
        }
      }
      .views-field-title{
        max-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
      }
      .views-field-field-ticket-link{
        padding-right: 20px;
        padding-left: 4px;
        max-width: 0;
        text-align: center;
        width: 30%;

        a {
          @include gold-button();
        }
      }
    }
    .more-link{
      margin: 10px 46px;
      font-size: 0.9em;
      a{
        @include gold-button();
        padding: 5px 28px;
      }
    }
  }
  .hancher-thirdrow-onethird{
    @include pane-shadow();
    font-family: $font-family-headings;
    .view-display-id-events_by_month_pane{
      .date-nav-wrapper{
        text-align: center;
        padding: 5px 0;
        a{
          color: black !important;
          text-decoration: none;
          padding: 5px;
        }
        h3{
          display: inline;
          font-size: 1.2em;
          padding: 0 10px;
        }
      }
      .attachment-before{
        table{
          td, th{
            text-align: center;
            &.active{
              background-color: #FFF;
              .events{
                color: $accent-gold-color;
                background-color: $gray-dark;
              }
            }
          }
          .events{
            display: block;
            background-color: $accent-gold-color;
            padding-top: 2px;
            height: 22px;
            width: 24px;
            border-color: $accent-gold-color;
          }
        }
      }
      .view-content{
        display: none;
      }
    }
  }

}

// Tickets Page

.page-tickets{
  .page-header{
    margin: 0;
  }

  .hancher-column-content-row3{
    display: none;
  }

  .hancher-column-content-row1,
  .hancher-column-content-row2,
  .hancher-column-content-row4,
  .hancher-column-content-row5{
    display: flex;
    justify-content: space-between;
    .panel-panel{
      flex-grow: 1;
      flex-shrink: 1;
      @include pane-shadow();
      padding: 20px;
      h2{
        margin: 0 0 10px 0;
        font-size: 1.3em;
      }
      .pane-content{
        font-size: 0.9em;
      }
    }
  }
}

// Support Page

.page-support{
  .page-header{
    margin: 0;
  }

  .hancher-column-content-row1,
  .hancher-column-content-row2{
    display: none;
  }

  .hancher-column-content-row3,
  .hancher-column-content-row4,
  {
    display: flex;
    justify-content: space-between;
    .panel-panel{
      @include pane-shadow();
      padding: 20px;
      h2{
        margin: 0 0 10px 0;
        font-size: 1.3em;
      }
      .pane-content{
        font-size: 0.9em;
      }
    }
  }
  .hancher-column-content-row3{
    .hancher-thirdrow-twothirds{
      width: 630px;
    }
  }
}

// About Page

.page-about{
  .page-header{
    margin: 0;
  }

  .hancher-column-content-row3,
  .hancher-column-content-row5{
    display: none;
  }

  .hancher-column-content-row1,
  .hancher-column-content-row2,
  .hancher-column-content-row4
  {
    display: flex;
    justify-content: space-between;
    .panel-panel{
      @include pane-shadow();
      padding: 20px;
      h2{
        margin: 0 0 10px 0;
        font-size: 1.3em;
      }
      .pane-content{
        font-size: 0.9em;
      }
    }
  }
}

// Contact Page

.page-contact{
  .page-header{
    margin: 0;
  }
  .hancher-column-content-row1,
  .hancher-column-content-row3,
  .hancher-column-content-row4,
  .hancher-column-content-row5{
    display: none;
  }
  .panel-pane{
    @include pane-shadow();
    padding: 20px;
    margin-bottom: 20px;
    h2{
      margin: 0 0 10px 0;
      font-size: 1.3em;
    }
    h3{
      font-size: 1.2em;
    }
    .pane-content{
      font-size: 0.9em;
    }
  }
}

// Construction Page

.page-construction{
  .page-header{
    margin: 0;
  }
  .panel-pane{
    @include pane-shadow();
    padding: 20px;
    margin-bottom: 20px;
    h2{
      margin: 0 0 10px 0;
      font-size: 1.3em;
    }
    h3{
      font-size: 1.2em;
    }
    .pane-content{
      font-size: 0.9em;
    }
    iframe{
      max-width: 100%;
    }
  }
}
