// Page Layout
// -----------------------------------------------------------------------------

@media (min-width: $screen-md){
  #main, .container{
      width: 960px;
      padding: 0;
      margin: 0 auto;
  }
}


@media (min-width: $screen-lg){
  #main , .container{
      width: 960px;
      padding: 0;
      margin: 0 auto;
  }
}



header{
  font-family: $font-family-headings;
  @media (min-width: $screen-md){
    #branding{
      .container{

        display: flex;
        align-items: center;
        justify-content: space-between;
        &:before, &:after{
          display: none;
        }
      }
    }
  }
  #site-brand{
    overflow: hidden;
    position: relative;
    #logo{
      float: none;
      max-width: 100%;
      height: auto;
    }
    a{
      text-decoration: none;
      font-weight: 300;
    }
    img{
      width: 615px;
      height: auto;
    }
    .site-name{
      position: absolute;
      right: -9999px;
    }
    .site-slogan{
      display: block;
      color: $accent-grey-color;
      font-size: 1.1em;
      margin-bottom: 10px;
      span{
        color: black;
      }
      @media only screen and (max-width: $screen-xs) {
        font-size: 0.8em;
        text-align: center;
      }
    }
  }

  #box-office{
    font-size: 1.2em;
    text-align: right;
    width: 300px;
    a{
      color: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      font-weight: 300;
      p{
        margin-bottom: 0;
        line-height: 1.2em;
      }
      span{
        text-transform: uppercase;
        font-size: 1.2em;
      }
      i{
        font-size: 2.6em;
        padding-left: 5px;
        color: #ffcd00;
      }
    }
    @media only screen and (max-width: $screen-md) {
      display: none;
    }
  }

  nav{
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.25);
    background-color: $nav-background-color;
    #superfish-1-toggle{
      color: black;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      width: 100%;
      display: inline-block;
      padding: 5px;
    }
    #superfish-1-accordion{
      .sf-clone-parent{
        display: none;
      }
      li{
        text-align: center;
        padding: 10px;
        a{
          color: black;
          text-transform: uppercase;
          font-weight: 600;
        }
        li{
          padding: 5px;
          a{
            font-weight: 500;
          }
        }
      }
    }


    @media (min-width: 753px){

      #superfish-1{
        display: flex;
        justify-content: space-around;
        .sf-sub-indicator{
          display: none;
        }
        li{
          padding: 5px 20px;

          a{
            color: black;
            text-transform: uppercase;
            font-weight: 600;
          }
          &:hover{
            background-color: $nav-background-rollover-color;
            a{
              color: white;
            }
          }
        }
        .sf-depth-1{
          ul{
            background-color: #FFF;
            background-image: none;
            border-radius: 0;
            border: none;
            padding: 10px 0 !important;
            top: 27px;
            box-shadow: 0px 2px 10px rgba(#232522, 0.5);
          }
          li{
            background-color: #FFF;
            text-align: left;
            padding: 2px 20px;
            &:hover{
              background-color: $accent-gold-color;
            }
            a{
              font-weight: 400;
              color: #242424;
              text-transform: none;
              text-decoration: none;
            }
          }
        }

      }
    }
  }
}

#main-wrapper{
  background-color: $content-background-color;
  .main{
    a{
      color: $link-color;
    }
    .page-header{
      border: none;
      h1{
        font-weight: 400;
        font-size: 2em;
      }
    }
  }

}

#footer{
  margin: 0;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
  .container{
    display: flex;
    justify-content: space-between;
    &:before, &:after{
      clear: none;
      content: none;
    }
  }

  @media(min-width: $screen-sm){
    .block__content{
        ul{
          font-family: $font-family-headings;
          font-weight: 400;
          padding: 0;
          margin: 0;
          list-style-type: none;
          li{
            display: inline-block;
            padding: 20px 20px 20px 0;
          }
        }
      }
    #social-links{
      padding: 10px;
      font-size: 1.8em;
      a{
        color: $accent-grey-color;
        text-decoration: none;
      }
      i{
        margin-left: 15px;
      }
    }

  }
}


#ui-wrapper {
  .container-smooth {
    position: relative;
  }

  .form-item-search-terms {
    .form-control {
      height: auto;
      line-height: normal;
      @include box-shadow(none);
    }
  }

  #edit-submit-search {
    width: auto;
    padding: 4px 8px;
  }
}

.file-video iframe {
  max-width: 100%;
}