.page-upcoming-events{
  @include white-background();
  .page-header{
    border-bottom: none;
  }
  h1{
    padding: 0;
    font-weight: 500;
    font-size: 1.3em;
  }
  .view-header{
    float: right;
    width: 360px;
    font-size: 0.9em;
    background-color: $gray-lighter;
    margin-top: -70px;
    margin-right: -20px;
    padding: 20px;
    p{
      margin: 0;
    }
    @media only screen and (max-width: $screen-sm) {
      display: none;
    }
  }
  .view-filters{
    font-family: $font-family-headings;
    font-size: 0.8em;
    border-bottom: 1px solid $accent-grey-color;
    label{
      display: block;
      text-align: center;
    }
    .form-select,
    #edit-reset{
      font-size: 0.8em;
      height: inherit;
      width: 140px;
    }
    #edit-reset{
      background: none;
      border: none;
      color: #000;
      text-transform: uppercase;
      width: 80px;
      margin-top: 12px;
    }
  }
  .view-content{
    font-family: $font-family-headings;
    .views-row{
      padding: 10px 0 0;
      border-bottom: 1px solid $accent-grey-color;
      position: relative;
      @include clearfix();
    }
    .views-field-field-thumbnail-image{
      padding-bottom: 10px;
      float: left;
      img{
        display: inline-block;
        margin-right: 20px;
      }
    }
    .views-field-field-primary-genre,
    .views-field-field-presented-by{
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.7em;

    }
    .views-field-field-presented-by{
      padding-bottom: 10px;
      max-width: 600px;
      @media only screen and (max-width: $screen-md-min) {
        max-width: 360px;
      }
      @media only screen and (max-width: $screen-sm-min) {
        max-width: none;
      }
      .views-label-field-presented-by,.field-content,.item-list, ul, li{
        display: inline;
      }
      li{
        margin: 0;
      }
    }
    .views-field-title{
      font-weight: 500;
      font-size: 1.2em;
    }
    .views-field-field-subtitle{
      font-weight: 600;
      font-size: 0.9em;
      p{
        margin: 0;
      }
    }
    .views-field-field-performance-date,
    .views-field-field-location{
      font-size: 0.8em;
      .date-display-range{
        display: inline;
      }
    }
    .views-field-view-node,
    .views-field-field-ticket-link{

      position:absolute;
      bottom: 10px;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.8em;
      @media only screen and (max-width: $screen-sm) {
        display: none;
      }
    }
    .views-field-view-node{
      right:180px;
      width: 120px;

      @include gold-button();
      a{
        color: #000 !important;
        text-decoration: none;
      }

    }
    .views-field-field-ticket-link{
      width: 180px;
      right: 0;
      padding: 5px 0;
      a{
        @include gold-button();
        color: #000 !important;
        font-size: 1em;

      }
    }
  }
}
