.node-type-page{

  .content{
    h2{
      font-size: 1.3em;
    }
    img{
      max-width: 100%;
      height: auto;
    }
  }

  .field-name-field-for-more-info{
    background-color: #e8e8e8;
    font-family: "Helvetica", "Ariel", sans-serif;
    margin-top: 20px;
    margin-left: -20px;
    margin-bottom: 20px;
    padding: 15px 20px;
    max-width: 300px;
    .field-label{
      font-size: 1.4em;
      font-family: $font-family-headings;
      font-weight: 500;
    }
    .field-items{
      font-size: 0.9em;
    }
  }
}
