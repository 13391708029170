.page-news, .view-display-id-news_by_tag_pane, .view-display-id-news_for_event_pane{
  @include white-background();
  .views-row{
    clear: left;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 0.9em;
    border-bottom: 1px solid $accent-grey-color;
    @include clearfix();
    &:last-child{
      border-bottom: none;
    }
    .views-field-field-thumbnail-image{
      float: left;
      margin-right: 10px;
    }
  }
}

.view-display-id-news_by_tag_pane,.view-display-id-news_for_event_pane{
  font-size: 1.1em;
}
