.view-season-events{
  .view-content{
    display: flex;
    flex-wrap: wrap;         // allow wrapping
    justify-content: space-between;
    .views-row{
      margin-bottom: 20px;
      @media only screen and (max-width: $screen-sm) {
        margin: 0 auto 20px;
      }
    }
  }
}

.pane-season-events-past-season-events{
  h2{
    font-size: 1.8rem;
  }
}
