.card-display{
  width: 303px;
  height: 340px;
  font-family: $font-family-headings;
  background-color: #FFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  h2, p{
    margin: 2px 0;
  }
  .field--name-field-status{
    position: absolute;
    font-size: 0.7em;
    &.error{
      background-color: #d10100;
      background-image: none;
      top: 10px;
      right: 0px;

      text-align: center;
      text-transform: uppercase;
      color: #FFF;
      padding: 5px 8px;
    }
    &.free{
      background-color: #777;
      top: 10px;
      right: 10px;
      width: 50px;
      padding: 19px 0;

      text-align: center;
      text-transform: uppercase;
      color: #FFF;
      @include border-radius(25px, 25px);
    }
  }

  .field--genre {
    text-transform: uppercase;
    padding: 15px 20px;
    font-size: 0.7em;
  }
  h2{
    font-size: 1em;
    padding: 0 20px;
    font-weight: 500;
    a{
      color:#000;
      text-decoration: none;
    }
  }

  .field-name-field-subtitle,.field--name-field-date-time{
    font-size: 0.9em;
    font-weight: 500;
    padding: 0 20px;
  }

  .field--name-field-date-time{
    font-weight: 300;
  }
  .card-front{
    position: relative;
    height: 340px;

  }

  .card-back{
  	display: none;
    position: relative;
    height: 340px;
    font-size: 1em;
    .event-back-view-top{
      height: 160px;
      .field--genre{
        margin-bottom: 20px;
      }
    }
    .event-back-view-bottom{
      padding: 20px;
      background-color: #e8e8e8;
      height: 180px;
      .field-name-body{
        font-size: 0.8em;
      }
      .more-info{
        position: absolute;
        bottom: 20px;
        a{
          @include gold-button();
        }
      }
    }
  }

  .event-front,
  .event-back{
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      border-width: 20px 20px 0 0;
      border-style: solid;
      box-shadow: 10px 10px 10px 10px $content-background-color;
    }
  }

  .event-front{
    &:after{
      border-color: #f0ba00 $content-background-color ;
    }
  }

  .event-back{
    &:after{
      border-color: #727272 $content-background-color ;
    }
  }
}
