// Helpers
// -----------------------------------------------------------------------------
@mixin white-background{
  #main-wrapper{
    padding: 20px 0;
    #main{
      margin: 20px auto;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
      .container{

        background-color: white;
        padding:0 20px;
      }
    }
  }
}

@mixin pane-shadow{
  background-color: #FFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

@mixin gold-button{
	background-color: $accent-gold-color;
  font-family: $font-family-headings;
  font-size: 0.8em;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 500;
	color: #000 !important;
	padding: 5px 20px;
	&:hover{
		background-color:#f6dd80;
	}
}

@mixin grey-button{
	background-color: $nav-background-rollover-color;
  font-family: $font-family-headings;
  font-size: 0.8em;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 500;
	color: #FFF !important;
	padding: 5px 20px;
}
