.node-type-event{

  #breadcrumb{
    display: none;
  }

  .hancher-event-full-width-top{
    .pane-node-field-full-width-image{
      @media only screen and (max-width: $screen-md) {
        img{
          max-width: 100%;
          height: auto;
          margin: 0 auto;
          display: block;
        }
      }
      @media only screen and (min-width: $screen-md) {
        background-image: url('../images/event_drop_shadow.png');
        background-repeat: no-repeat;
        background-position: center bottom;
        height: 420px;
      }
    }

  }

  .pane-node-field-event-addthis{
    margin-bottom: 10px;
    div{
      display: inline-block;
    }
    .pane-title{
      text-transform: uppercase;
      font-family: $font-family-headings;
      font-size: 0.8em;
      display: inline-block;
    }
    .pane-content{
      margin-right: -3px;
    }
    .addthis_default_style .at300b{
      float: none;
      padding: 0;
      span{
        vertical-align: middle;
      }
    }
  }

  .pane-node-field-localist-url{
    padding: 5px 0;
    text-transform: uppercase;
    font-family: $font-family-headings;
    font-size: 0.8em;
    a{
      text-decoration: none;
    }
  }
  .pane-node-field-photo-credit{
    font-size: 0.7em;
    padding-bottom: 5px;
    font-family: $font-family-headings;
    div{
      display: inline;
    }
  }
  .field-name-field-youtube-video{
    padding-top: 20px;
    iframe{
      width: 320px;
      height: 195px;
    }
  }
  .field-collection-item-field-date-time{
    &:after{
      clear: none;
      content: none;
    }
  }

  .pane-node-field-primary-genre,
  .pane-node-field-secondary-genre,{
    display: inline;
    text-transform: uppercase;
    font-family: $font-family-headings;
    font-size: 0.8em;
    div{
      display: inline;
    }
  }
  .pane-node-field-secondary-genre{
    &:before{
      content: " | ";
    }
  }

  .pane-page-title{
    font-size: 1.6em;
    font-weight: 500;
    h2{
      margin: 20px 0 0 0;
    }
    .pane-content{
      display: none;
    }
  }
  .pane-node-field-subtitle{
    font-size: 1.4em;
    font-weight: 500;
    font-family: $font-family-headings;
    p{
      margin: 0;
    }
  }

  .pane-node-field-location{
    font-family: $font-family-headings;
    font-weight: 500;
    padding: 5px 0;
  }

  .pane-node-field-presented-by{
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: $font-family-headings;
    font-size: 0.8em;
    div{
      display: inline;

    }
  }

  .pane-node-field-ticket-prices{
    font-family: $font-family-headings;
    font-size: 0.8em;
    padding-bottom: 5px;
    .pane-title{
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .pane-node-field-event-discounts{
    font-family: $font-family-headings;
    font-size: 0.8em;
    p{
      margin-top: 0;
    }
  }

  .pane-node-field-date-time{
    font-family: $font-family-headings;
    font-size: 0.9em;
    padding-bottom: 20px;
    .entity-field-collection-item{
      padding: 5px 0;
      .field{
        display: inline-block;
        div{
          display: inline-block;
        }
      }
      .field-name-field-performance-date{
        width: 330px;
      }
    }
  }

  .call-box-office{
    display: none;
    font-size: 0.9em;
    @media only screen and (max-width: $screen-md-min) {
      display: block;
      margin-bottom: 20px;
    }
  }

  .pane-node-body{
    font-size: 0.9em;
  }

  .pane-node-field-artist-website,
  .pane-node-field-playbill,
  .pane-node-field-audience-comments{
    margin: 10px 0;
    display: inline-block;
    a{
      @include grey-button();
    }
  }

  .hancher-event-full-width-bottom{
    .panel-pane{
      padding: 0 20px;
      max-width: 960px;
    }
    .pane-residency-events-panel-pane-1,
    .pane-node-field-residency-events{
      padding: 10px 20px;
      background-color: #e8e8e8;
      font-size: 0.85em;
      h2{
        font-size: 1.3em;
        margin: 0;
        padding: 10px 0;
      }
    }
    .pane-residency-events-panel-pane-1{
      padding-bottom: 0;
      .pane-content{
        padding: 10px 0;
        border-top: 1px solid $accent-grey-color;

        .view-header{
          text-transform: uppercase;
          font-family: $font-family-headings;
        }
        .views-row{
          margin-bottom: 10px;
          .views-field-title,
          .views-field-field-other-location{
            font-weight: 600;
          }
        }
      }
    }

    .pane-news-news-for-event-pane{
      font-size: 0.9em;
      padding: 10px 20px;
      h2{
        margin: 0;
        font-size: 1.1em;
      }
      .view-header{
        font-size: 0.8em;
        font-style: italic;
      }
    }

    .pane-1{
      .pane-content{
        margin-top: 10px;
        font-size: 0.9em;
        padding: 20px 0;
        border-top: 1px solid $accent-grey-color;
        p{
          margin: 0;
        }
      }

    }

  }
}
